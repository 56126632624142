import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { formatPeso } from "../../util";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";

export function TableServicesUniversalLink({
  services,
  removeService,
  changeServiceDiscount,
  changeServiceQty,
  changeServicePrice,
  enabledDisccount,
}) {
  return (
    <div className="w-full pt-4 items-center text-xs font-bold text-v2-gray-title-client">
      <div className="flex flex-col w-full">
        <div className="flex items-center text-left  bg-blue-50 text-gray-700 px-4 py-4 rounded-t-lg">
          <span className="w-1/3 text-sm font-semibold">Servicio</span>
          <span className="w-1/5 text-sm font-semibold">Cantidad </span>
          <span className="w-1/5 text-sm font-semibold">Precio Unitario</span>
          {enabledDisccount && (
            <span className="flex-1 text-sm font-semibol">Descuento</span>
          )}
          <span className="ml-auto text-sm font-semibold text-center 2xl:text-right">
            Total
          </span>
        </div>

        {services.map((service) => (
          <ServiceRow
            key={service.id}
            service={service}
            removeService={removeService}
            changeServiceDiscount={changeServiceDiscount}
            changeServiceQty={changeServiceQty}
            changeServicePrice={changeServicePrice}
            enabledDisccount={enabledDisccount}
          />
        ))}
      </div>
    </div>
  );
}

const discountList = [
  { type: "direct", symbol: "$" },
  { type: "percent", symbol: "%" },
];

function ServiceRow({
  service,
  removeService,
  changeServiceDiscount,
  changeServiceQty,
  changeServicePrice,
  enabledDisccount,
}) {
  const [quantity, setQuantity] = useState(1);
  const [price, setPrice] = useState(service.price);
  const [discount, setDiscount] = useState(0);
  const [discountType, setDiscountType] = useState(discountList[0].type);
  const total = quantity * service.price;

  const handleQuantityChange = (e) => {
    const newQuantity = e.target.value;

    if (0 < newQuantity || e.target.value.length === 0) {
      setQuantity(newQuantity);
      changeServiceQty(service.id, newQuantity);
    }
  };

  const handlePriceChange = (e) => {
    const newPrice = e.target.value;

    if (Number(newPrice) > 0 || newPrice === "") {
      setPrice(newPrice);
      changeServicePrice(service.id, newPrice);
    }
  };

  const handleDiscount = (e) => {
    const value = e.target.value.replace(/[^0-9.]/g, "");

    if (value === "") {
      setDiscount("");
      changeServiceDiscount(service.id, 0, discountType);
    } else if (
      (discountType === "direct" &&
        parseInt(value) <= service.price * quantity) ||
      (discountType === "percent" && parseInt(value) <= 100)
    ) {
      setDiscount(value);
      changeServiceDiscount(service.id, value, discountType);
    }
  };

  const handleDiscountType = (e) => {
    const value = e.target.value;
    setDiscountType(value);
    setDiscount(0);
    changeServiceDiscount(service.id, 0, value);
  };

  useEffect(() => {}, [discount]);

  return (
    <div className="w-full">
      <div
        className={`flex items-start md:items-center flex-col md:flex-row w-full`}
      >
        <div className="flex items-center w-1/3 gap-2 py-2 text-sm text-gray-900 font-normal capitalize text-left">
          <div className="text-2xl text-v2-input-text">
            <FontAwesomeIcon
              icon={faCircleXmark}
              className="cursor-pointer bg-white h-5 w-5"
              onClick={() => removeService(service.id)}
            />
          </div>
          <span>{service.name}</span>
        </div>
        <div className="w-1/5 py-2 text-sm text-gray-900 sm:table-cell capitalize text-left">
          <input
            type="text"
            inputMode="number"
            className="font-normal w-20 text-v2-text-bar-steps border-v2-gray-border-tables focus:ring-v2-blue-text-login focus:border-v2-blue-text-login transition-all py-1 rounded-md"
            value={quantity}
            onChange={handleQuantityChange}
            maxLength={5}
          />
        </div>
        <div className="w-1/5 py-2 text-sm text-gray-900 sm:table-cell capitalize text-left">
          <input
            type="text"
            inputMode="number"
            className="font-normal w-20 text-v2-text-bar-steps border-v2-gray-border-tables focus:ring-v2-blue-text-login focus:border-v2-blue-text-login transition-all py-1 rounded-md"
            value={price}
            onChange={handlePriceChange}
          />
        </div>
        <div className="flex-1 py-2 text-sm font-normal text-gray-900 sm:table-cell capitalize text-left">
          {enabledDisccount && (
            <div className="flex flex-col -ml-2 mr-4">
              <div className="flex w-full">
                <div className="w-full text-base text-v2-text-bar-steps outline-none flex items-center justify-center relative">
                  <div
                    className={`${
                      discountType != "direct" ? "hidden" : "absolute"
                    }  flex items-center top-0 left-0 bottom-0 text-sm mx-2`}
                  >
                    <span className="font-normal block text-v2-gray-title-client">
                      $
                    </span>
                  </div>
                  <input
                    type="text"
                    inputMode="number"
                    value={discount}
                    onChange={handleDiscount}
                    // onChange={(e) => changeServicePriceDiscount(e)}
                    className={`font-normal border-v2-gray-border-tables border-r-0 text-v2-text-bar-steps  transition-all block w-full ${
                      discountType != "direct" ? "pl-2" : "pl-6 "
                    } pr-4 py-1 rounded-l-md `}
                  />
                </div>
                <select
                  id="disccount_type"
                  name="disccount_type"
                  className="w-fit rounded-r-md py-0 px-4 border-none bg-v2-blue-text-login text-white"
                  onChange={handleDiscountType}
                  defaultValue={discountType}
                  //   disabled={!selected}
                >
                  {discountList.map((disccount_type, i) => (
                    <option key={i} value={disccount_type.type}>
                      {disccount_type?.symbol}
                    </option>
                  ))}
                </select>
              </div>
              {/* {selectedService?.discount_error && (
                <span className="text-error text-xs">
                  {selectedService?.discount_error}
                </span>
              )} */}
            </div>
          )}
        </div>
        <div className="px-3 py-2 text-sm font-normal text-gray-900 sm:table-cell capitalize text-right">
          {enabledDisccount ? (
            <div className="flex flex-col">
              <span className="text-gray-500 ">
                <del>{formatPeso(total)}</del>
              </span>
              <span className="text-gray-900 font-semibold">
                {formatPeso(service.priceWithDiscount)}
              </span>
            </div>
          ) : (
            <span className="text-gray-900 font-semibold">
              {formatPeso(total)}
            </span>
          )}
        </div>
      </div>
    </div>
  );
}

TableServicesUniversalLink.propTypes = {
  services: PropTypes.array,
  removeService: PropTypes.func,
  changeServiceDiscount: PropTypes.func,
  changeServiceQty: PropTypes.func,
  changeServicePrice: PropTypes.func,
  enabledDisccount: PropTypes.bool,
};

ServiceRow.propTypes = {
  service: PropTypes.array,
  removeService: PropTypes.func,
  changeServiceDiscount: PropTypes.func,
  changeServiceQty: PropTypes.func,
  changeServicePrice: PropTypes.func,
  setService: PropTypes.func,
  enabledDisccount: PropTypes.bool,
};
