import { useContext, useEffect, useState } from "react";
import usePaymentRules from "./usePaymentRules";
import {
  paymentInterval,
  paymentMethods,
  paymentTypes,
} from "../components/billing/paymentLinkValues";
import moment from "moment";
import useFormatDate from "./useFormatDate";
import useConvertDatePicker from "./useConvertDatePicker";
import useGetDate from "./useGetDate";
import useReverseDatepicker from "./useReverseDatepicker";
import { getServices } from "../apiClient/operations/services";
import { AuthContext } from "../cognito/AuthProvider";

export function useUniversalLink() {
  const { user } = useContext(AuthContext);
  const { installments: getInstalmentsEnabled } = usePaymentRules();

  const { convertDatePicker } = useConvertDatePicker();
  const { reverseDatePicker } = useReverseDatepicker();
  const { formatDate } = useFormatDate();
  const { getDate } = useGetDate();

  const [services, setServices] = useState([]);

  const [expirationDate, setExpirationDate] = useState(
    convertDatePicker(formatDate(moment().add(7, "days"), "YYYY-MM-DD"))
  );
  const [subscriptionDate, setSubscriptionDate] = useState(
    convertDatePicker(
      formatDate(moment().add(1, "M").startOf("month"), "YYYY-MM-DD")
    )
  );
  const minimumDate = convertDatePicker(formatDate(getDate(), "YYYY-MM-DD"));

  const getEpochDate = (date, time) =>
    moment(reverseDatePicker(date))
      .set(time ? time : { hour: 23, minute: 59, second: 59, millisecond: 59 })
      .valueOf();

  const [formData, setFormData] = useState({
    name: user.name,
    email: "",
    concept: "",
    expirationDate: getEpochDate(expirationDate),
    selectedPaymentType: paymentTypes[0],
    selectedPaymentMethods: [paymentMethods[0]],
    selectedMsi: getInstalmentsEnabled(0)[0],

    subscriptionDate: getEpochDate(subscriptionDate, {
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    }),
    numberOfPayments: 0,
    selectedPaymentInterval: paymentInterval[0],
    applyCharge: true,

    description: "",
    successUrl: "",
  });

  const [msi, setMsi] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [showTooltip, setShowTooltip] = useState(false);
  const [applyChargeDisabled, setApplyChargeDisabled] = useState(false);

  const [selectedServices, setSelectedServices] = useState([]);

  const onChangePaymentType = (type) => {
    const { selectedPaymentMethods, selectedMsi } = formData;
    setFormData({
      ...formData,
      selectedPaymentType: type,
      selectedPaymentMethods: selectedPaymentMethods.filter((method) =>
        type.allowedPaymentMethods.includes(method.value)
      ),
      selectedMsi: type.id == 2 ? msi.find((msi) => msi.id == 1) : selectedMsi,
    });
  };

  const selectService = (e) => {
    e.preventDefault();
    if (e.target.value == 0) return;
    const selectedService = services.find(
      (service) => service.sku == e.target.value
    );

    setSelectedServices((currentServices) => [
      ...currentServices,
      {
        id: selectedService.id,
        sku: selectedService.sku,
        name: selectedService.name,
        price: selectedService.price,
        currency: selectedService.currency,
        legal_type: selectedService.legal_type,
        category: selectedService.category,
        periodicity: selectedService.periodicity,

        discount: 0,
        qty: 1,
        discountType: "direct",
        priceWithDiscount: selectedService.price,
      },
    ]);
    e.target.value = 0;
  };

  const removeService = (idToRemove) => {
    setSelectedServices((currentServices) =>
      currentServices.filter((service) => service.id !== idToRemove)
    );
  };

  const changeServiceDiscount = (idToChange, discount, discountType) => {
    setSelectedServices((currentServices) =>
      currentServices.map((service) => {
        return service.id === idToChange
          ? {
              ...service,
              discount,
              discountType,
              priceWithDiscount:
                discountType === "direct"
                  ? service.price * service.qty - discount
                  : service.price * service.qty -
                    (service.price * service.qty * discount) / 100,
            }
          : service;
      })
    );
  };

  const changeServiceQty = (idToChange, newQuantity) => {
    setSelectedServices((currentServices) =>
      currentServices.map((service) => {
        return service.id === idToChange
          ? {
              ...service,
              qty: newQuantity,
              priceWithDiscount:
                service.discountType === "direct"
                  ? service.price * newQuantity - service.discount
                  : service.price * newQuantity -
                    (service.price * newQuantity * service.discount) / 100,
            }
          : service;
      })
    );
  };

  const changeServicePrice = (idToChange, newPrice) => {
    setSelectedServices((currentServices) =>
      currentServices.map((service) => {
        return service.id === idToChange
          ? {
              ...service,
              price: newPrice,
              priceWithDiscount:
                service.discountType === "direct"
                  ? newPrice * service.qty - service.discount
                  : newPrice * service.qty -
                    (newPrice * service.qty * service.discount) / 100,
            }
          : service;
      })
    );
  };

  const selectPaymentMethod = (paymentMethod) => {
    const { selectedPaymentMethods } = formData;
    const selectedPaymentMethod = selectedPaymentMethods.find(
      (method) => method.id == paymentMethod.id
    );
    if (selectedPaymentMethod) {
      setFormData({
        ...formData,
        selectedPaymentMethods: selectedPaymentMethods.filter(
          (method) => method.id != paymentMethod.id
        ),
      });
    } else {
      setFormData({
        ...formData,
        selectedPaymentMethods: [...selectedPaymentMethods, paymentMethod],
      });
    }
  };

  const getFilteredServices = async () => {
    try {
      const response = await getServices();
      setServices(
        response.data.map((service) => {
          service.price = service.price / 100;
          return service;
        })
      );
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getFilteredServices();
  }, []);

  useEffect(() => {
    setFormData((currentData) => ({
      ...currentData,
      expirationDate: getEpochDate(expirationDate),
    }));
  }, [expirationDate]);

  useEffect(() => {
    const hour = { hour: 23, minute: 59, second: 59, millisecond: 59 };
    const epochDate = getEpochDate(subscriptionDate, {
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });
    if (
      moment(reverseDatePicker(subscriptionDate))
        .set(hour)
        .diff(moment().set(hour), "days") > 0
    ) {
      setApplyChargeDisabled(false);
      setFormData({
        ...formData,
        applyCharge: true,
        subscriptionDate: epochDate,
      });
    } else {
      setApplyChargeDisabled(true);
      setFormData({
        ...formData,
        applyCharge: false,
        subscriptionDate: epochDate,
      });
    }
  }, [subscriptionDate]);

  useEffect(() => {
    setTotalAmount(
      selectedServices.reduce(
        (accumulator, currentService) =>
          accumulator + currentService.qty * currentService.price,
        0
      )
    );
  }, [selectedServices]);

  useEffect(() => {
    setMsi(getInstalmentsEnabled(totalAmount));
  }, [totalAmount]);

  return {
    formData,
    setFormData,
    extraData: {
      expirationDate,
      subscriptionDate,
      services,
      selectedServices,
      msi,
      totalAmount,
      showTooltip,
      minimumDate,
      applyChargeDisabled,
    },
    helperFunctions: {
      onChangePaymentType,
      selectService,
      removeService,
      changeServiceDiscount,
      changeServiceQty,
      changeServicePrice,
      selectPaymentMethod,
      setShowTooltip,
      setExpirationDate,
      setSubscriptionDate,
    },
  };
}
