import React from "react";
import Editor from "@monaco-editor/react";
import PropTypes from "prop-types";

export function CodeEditor({ code, setCode }) {
  const handleOnChange = (value) => {
    setCode(value);
  };

  return (
    <Editor
      height="40vh"
      defaultLanguage="html"
      language="html"
      theme="light"
      onChange={handleOnChange}
      options={{
        fontSize: 14,
        tabSize: 2,
        insertSpaces: true,
        automaticLayout: true,
        minimap: {
          enabled: false,
        },
        scrollbar: { horizontal: "hidden" },
        wordWrap: "on",
        renderWhitespace: "none",
        lineNumbers: "on",
        formatOnType: true,
        formatOnPaste: true,
      }}
      value={code.trim()}
    />
  );
}

CodeEditor.propTypes = {
  code: PropTypes.string,
  setCode: PropTypes.func,
};
