import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import PropTypes from "prop-types";
import useFormatDate from "../../hooks/useFormatDate";
import useFormatterCurrency from "../../hooks/useFormatterCurrency";
import useReverseDatepicker from "../../hooks/useReverseDatepicker";

export function UniversalLinkSuccess({
  formData,
  paymentLink,
  totalAmount,
  expirationDate,
}) {
  const [linkCopied, setLinkCopied] = useState(false);
  const { reverseDatePicker } = useReverseDatepicker();
  const [shortLinkCopied, setShortLinkCopied] = useState(false);
  const { concept, name, email } = formData;
  const { formatDate } = useFormatDate();
  const { formatterCurrency } = useFormatterCurrency();

  const copyLinkToClipboard = async (text) => {
    if ("clipboard" in navigator) {
      return navigator.clipboard.writeText(text);
    }
  };

  const handleCopyLink = (url) => {
    copyLinkToClipboard(url).then(() => {
      setLinkCopied(true);
      setTimeout(() => {
        setLinkCopied(false);
      }, 5000);
    });
  };

  const handleCopyShortLink = (url) => {
    copyLinkToClipboard(url).then(() => {
      setShortLinkCopied(true);
      setTimeout(() => {
        setShortLinkCopied(false);
      }, 5000);
    });
  };
  return (
    <div className="w-full border border-v2-gray-border-tables rounded-10 pt-6 pb-4 mt-3 bg-white flex xs:flex-col md:flex-row">
      <div className="xs:w-full md:w-7/12 px-4">
        <div className="w-full xs:text-center md:text-left text-v2-border-alert-success text-1.5xl font-bold leading-8 tracking-wide">
          ¡Felicidades!
          <span className="block">Terminaste el proceso.</span>
        </div>
        {/* <div className="w-full xs:text-center md:text-left text-base text-v2-input-text opacity-60 leading-4 pt-2">
          Ahora podrás enviarle este link de cobro al cliente
        </div> */}
        <div className="xs:w-full lg:w-4/6 mt-4 border border-dashed border-v2-gray-border-tables rounded-10 p-4">
          <div className="text-v2-input-text text-base font-semibold">
            {concept}
            <span className="pl-1 text-button-orange block text-2xl font-bold">
              {formatterCurrency(totalAmount)}
            </span>
          </div>
          <div className="text-v2-input-text opacity-60">
            Vence el
            <span className="pl-1 font-semibold">
              {formatDate(
                reverseDatePicker(expirationDate),
                "dddd, DD MMMM YYYY"
              )}
            </span>
          </div>
          <div className="w-full mt-3">
            <div className="border-t border-dashed border-v2-gray-border-tables" />
          </div>
          <div className="text-v2-input-text opacity-60 mt-3">
            Creador:
            <span className="pl-1 font-semibold">{name}</span>
          </div>
          <div className="text-v2-input-text opacity-60 pt-1">
            Correo:
            <span className="pl-1 font-semibold">{email}</span>
          </div>
          {/* <div className="text-v2-input-text opacity-60 pt-1">
            Móvil:
            <span className="pl-1 font-semibold">{account?.phone_number}</span>
          </div> */}
        </div>
      </div>
      <div className="xs:w-full md:w-5/12 px-4">
        <div className="w-full flex flex-col justify-center items-center">
          <div className="w-80 xs:hidden md:block">
            <img
              src="https://cdn-fixat-mx.s3.amazonaws.com/intranet/fixi_thankyou.png"
              alt="Fixi - Thank you"
            />
          </div>
          <div className="w-full mt-4">
            <div className="w-full border border-dashed border-v2-gray-border-dashed-amount flex justify-between items-center p-2">
              <div className="text-xs text-v2-gray-title-client font-bold w-10/12 truncate">
                Link corto:
                <input
                  className="block text-base text-v2-input-text font-normal outline-none w-full"
                  value={paymentLink.short_url}
                  readOnly
                />
              </div>
              <div
                className="cursor-pointer h-10 w-14 rounded-5 bg-button-orange text-white flex justify-center items-center"
                onClick={() => handleCopyShortLink(paymentLink.short_url)}
              >
                <FontAwesomeIcon icon={faCopy} />
              </div>
            </div>
            <div className="w-full h-6">
              {shortLinkCopied && (
                <span className="text-base text-v2-green-message-copied">
                  Link copiado con éxito
                </span>
              )}
            </div>
            <div className="w-full border border-dashed border-v2-gray-border-dashed-amount flex justify-between items-center p-2 mt-1">
              <div className="text-xs text-v2-gray-title-client font-bold w-10/12 truncate">
                Link:
                <input
                  className="block text-base text-v2-input-text font-normal outline-none w-full"
                  value={paymentLink.url}
                  readOnly
                />
              </div>
              <div
                className="cursor-pointer h-10 w-14 rounded-5 bg-button-orange text-white flex justify-center items-center"
                onClick={() => handleCopyLink(paymentLink.url)}
              >
                <FontAwesomeIcon icon={faCopy} />
              </div>
            </div>
            <div className="w-full h-6">
              {linkCopied && (
                <span className="text-base text-v2-green-message-copied">
                  Link copiado con éxito
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

UniversalLinkSuccess.propTypes = {
  formData: PropTypes.object,
  paymentLink: PropTypes.object,
  totalAmount: PropTypes.number,
  expirationDate: PropTypes.object,
};
