import React from "react";
import HeadersDataTables from "../../hooks/HeadersDataTables";
import PropTypes from "prop-types";
import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import useFormatterCurrency from "../../hooks/useFormatterCurrency";
import useReverseDatepicker from "../../hooks/useReverseDatepicker";

import { capitalize } from "../../tools/stringFormatter";
import useFormatDate from "../../hooks/useFormatDate";

import Button from "../../hooks/Button";

export function UniversalLinkOverview({
  formData,
  dates,
  selectedServices,
  totalAmount,
  htmlDescription,
  createUniversalLink,
  enabledDisccount,
  isSubmitDisable,
}) {
  const {
    name,
    concept,
    email,
    selectedPaymentType,
    selectedPaymentMethods,
    selectedMsi,
    selectedPaymentInterval,
  } = formData;

  const { expirationDate, subscriptionDate } = dates;

  const { formatterCurrency } = useFormatterCurrency();
  const { reverseDatePicker } = useReverseDatepicker();
  const { formatDate } = useFormatDate();

  return (
    <div className="xs:w-full lg:w-2/4">
      <div className="w-full border border-v2-gray-border-tables bg-white rounded-10 pb-4 mb-4">
        <HeadersDataTables
          icon={faCartShopping}
          text="Resumen del cobro"
          background={true}
          padding={0}
          bgColor="bg-button-orange"
          textColor="text-white"
        />

        <>
          <div className="w-full px-4 pt-3 items-center text-xs font-bold text-v2-gray-title-client">
            Creador:
            <span className="text-base font-normal block text-v2-input-text">
              {name}
            </span>
          </div>
          <div className="w-full px-4 xs:block lg:flex items-center text-xs font-bold text-v2-gray-title-client gap-2">
            <div className="xs:w-full lg:w-3/5 xl:w-1/2 text-xs font-bold text-v2-gray-title-client pt-3">
              Correo:
              <span className="text-base font-normal block text-v2-input-text overflow-x-auto">
                {email}
              </span>
            </div>
          </div>
          <div className="w-full px-4 mt-3">
            <div className="border-t border-dashed border-v2-gray-border-tables" />
          </div>
        </>

        {concept && concept != "" && (
          <div className="w-full px-4 pt-4 items-center text-xs font-bold text-v2-gray-title-client">
            Concepto:
            <div className="flex justify-between text-v2-input-text">
              <span className="text-base leading-snug font-normal block">
                {concept}
              </span>
            </div>
          </div>
        )}
        {selectedServices.length > 0 && (
          <>
            <div className="w-full px-4 pt-5 items-center text-xs font-bold text-v2-gray-title-client">
              Servicios seleccionados:
              {selectedServices.map((service, i) => (
                <div key={i} className={i == 0 ? "pt-1" : "pt-2"}>
                  <div className="flex justify-between text-v2-input-text">
                    <span className="text-base leading-snug font-normal block">
                      {service.name}
                    </span>
                    {enabledDisccount ? (
                      <div className="flex flex-col">
                        <span className="text-gray-500 ">
                          <del>
                            {formatterCurrency(service.price * service.qty)}
                          </del>
                        </span>
                        <span className="text-gray-900 font-semibold">
                          {formatterCurrency(service.priceWithDiscount)}
                        </span>
                      </div>
                    ) : (
                      <span className="text-base leading-snug font-normal block ml-4">
                        {isNaN(service.price)
                          ? formatterCurrency(0)
                          : formatterCurrency(service.price * service.qty)}
                      </span>
                    )}
                  </div>
                  <div className="flex justify-between -mt-1">
                    <span className="text-sm font-normal block text-v2-gray-title-client">
                      Cantidad: {service.qty}
                    </span>
                    {service.qty > 1 && (
                      <span className="text-sm font-normal block text-v2-gray-title-client ml-4">
                        {isNaN(service.price)
                          ? formatterCurrency(0)
                          : formatterCurrency(service.price)}{" "}
                        c/u
                      </span>
                    )}
                  </div>
                </div>
              ))}
            </div>
            <div className="w-full px-4 mt-3">
              <div className="border-t border-dashed border-v2-gray-border-tables" />
            </div>
          </>
        )}
        {selectedPaymentMethods.length > 0 && (
          <>
            <div className="w-full px-4 pt-4 items-center text-xs font-bold text-v2-gray-title-client">
              Métodos de pago:
              {selectedPaymentMethods
                .sort((a, b) => a.id - b.id)
                .map((paymentMethod, i) => (
                  <div
                    key={i}
                    className="pt-2 w-full flex flex-row items-center justify-between text-v2-input-text"
                  >
                    <div className="flex">
                      <div className="bg-v2-input-text w-1 h-1 rounded-full mt-2 mr-1" />
                      <div>
                        <span className="text-base font-normal block leading-snug">
                          {paymentMethod.name}
                        </span>
                        {selectedMsi.id &&
                          selectedMsi.id != 1 &&
                          paymentMethod.id == 1 && (
                            <span className="text-sm font-normal block text-v2-gray-title-client -mt-1">
                              {selectedMsi.name}
                            </span>
                          )}
                      </div>
                    </div>
                    <img
                      src={paymentMethod.imageUrl}
                      alt={paymentMethod.name}
                      className="h-5"
                    />
                  </div>
                ))}
            </div>
          </>
        )}
        <div className="w-full px-4 pt-4 items-center text-xs font-bold text-v2-gray-title-client">
          Fecha de caducidad:
          <div className="flex justify-between text-v2-input-text">
            <span className="text-base leading-snug font-normal block">
              {capitalize(
                formatDate(
                  reverseDatePicker(expirationDate),
                  "dddd, DD MMMM YYYY"
                )
              )}
            </span>
          </div>
        </div>
        {selectedPaymentType.id == 2 && (
          <div className="w-full px-4 pt-4 items-center text-xs font-bold text-v2-gray-title-client">
            Primer cobro:
            <div className="flex justify-between text-v2-input-text">
              <span className="text-base leading-snug font-normal block">
                {capitalize(
                  formatDate(
                    reverseDatePicker(subscriptionDate),
                    "dddd, DD MMMM YYYY"
                  )
                )}
              </span>
            </div>
          </div>
        )}
        <div className="w-full px-4 mt-4">
          <div className="w-full text-xl p-4 font-bold border border-dashed border-v2-gray-border-tables rounded-b-md items-center flex flex-col justify-center">
            <span className="text-v2-input-text">Total:</span>
            <span className="block text-button-orange">
              {formatterCurrency(isNaN(totalAmount) ? 0 : totalAmount)}
            </span>
            {selectedPaymentType.id == 2 && (
              <span className="text-v2-input-text text-sm font-normal">
                Facturación {selectedPaymentInterval.name.toLowerCase()}
              </span>
            )}
          </div>
        </div>

        <div className="w-full px-4 pt-4 items-center text-xs font-bold text-v2-gray-title-client">
          Descripción HTML:
          <p className="text-button-orange text-sm font-bold mt-4">
            {formData.description}
          </p>
          <div dangerouslySetInnerHTML={{ __html: htmlDescription }} />
        </div>
      </div>
      <div className="w-full mt-3 mb-4">
        <Button
          disabled={isSubmitDisable}
          onClick={createUniversalLink}
          heigth="h-11"
        >
          Crear link
        </Button>
      </div>
    </div>
  );
}

UniversalLinkOverview.propTypes = {
  formData: PropTypes.object,
  dates: PropTypes.object,
  selectedServices: PropTypes.array,
  totalAmount: PropTypes.number,
  htmlDescription: PropTypes.string,
  createUniversalLink: PropTypes.func,
  enabledDisccount: PropTypes.bool,
  isSubmitDisable: PropTypes.bool,
};
