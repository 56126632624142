import React, { useContext, useEffect, useState } from "react";
import SecondaryLoader from "../../hooks/SecondaryLoader";
import Nav from "../../hooks/Nav";
import { Link, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faCalendar,
  faCircleCheck,
  faCircleQuestion,
  faFileInvoiceDollar,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { AuthContext } from "../../cognito/AuthProvider";
import BreadCrumb from "../../tailwindUI/BreadCrumb";
import HeadersDataTables from "../../hooks/HeadersDataTables";
import {
  defaultCode,
  paymentInterval,
  paymentMethods,
  paymentTypes,
} from "./paymentLinkValues";
import DatePicker from "react-modern-calendar-datepicker";
import { AlertContext } from "../../context/AlertContext";
import Select from "../../hooks/Select";
import { useUniversalLink } from "../../hooks/useUniversalLink";
import Tooltip from "../../hooks/Tooltip";
import useFormatterCurrency from "../../hooks/useFormatterCurrency";
import Toogle from "../../tailwindUI/Toogle";
import { TagIcon } from "@heroicons/react/24/solid";
import { TableServicesUniversalLink } from "./TableServicesUniversalLink";
import { CodeEditor } from "./CodeEditor";
import { UniversalLinkOverview } from "./UniversalLinkOverview";
import { createUniversalPaymentLink } from "../../apiClient/operations/paymentLinksOperations";
import { UniversalLinkSuccess } from "./UnversalLinkSuccess";

const roadMap = [
  { name: "Links de cobro", url: "/payment-links", current: false },
  {
    name: "Crear link universal",
    url: "/payment-links/create-universal-link",
    current: true,
  },
];

function CreateUniversalPaymentLink() {
  const history = useHistory();
  const { formatterCurrency } = useFormatterCurrency();
  const { user } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({ email: false });
  const { setAlert } = useContext(AlertContext);

  const [enabledDisccount, setEnabledDisccount] = useState(false);
  const [hasSuccessUrl, setHasSuccessUrl] = useState(false);

  const [htmlDescription, setHtmlDescription] = useState(defaultCode);

  const [paymentLink, setPaymentLink] = useState(null);

  const [isSubmitDisable, setIsSubmitDisable] = useState(true);

  const [totalAmountWithDiscount, setTotalAmountWithDiscount] = useState(0);

  const { formData, setFormData, extraData, helperFunctions } =
    useUniversalLink();

  const {
    expirationDate,
    subscriptionDate,

    services,
    selectedServices,
    msi,
    totalAmount,
    showTooltip,
    minimumDate,
    applyChargeDisabled,
  } = extraData;

  const {
    email,
    name,
    concept,
    selectedPaymentType,
    selectedPaymentMethods,
    selectedMsi,

    numberOfPayments,
    selectedPaymentInterval,
    applyCharge,

    description,
    successUrl,
  } = formData;

  const formatToSingleLine = (str) => {
    return str.replace(/\s+/g, " ").replace(/"/g, "'").trim();
  };

  function parseDecimalToInt(input) {
    const num = typeof input === "string" ? parseFloat(input) : input;
    let result = Math.floor(num);
    if (num < 0) {
      result = Math.ceil(num);
    }
    return result;
  }

  const createUniversalLink = async () => {
    try {
      if (Object.values(errors).some((value) => value)) return;

      setLoading(true);
      const request = {
        created_by: {
          name: name,
          email: email,
        },
        name: concept,
        expires_at: formData.expirationDate,
        allowed_payment_methods: selectedPaymentMethods.map(
          (method) => method.value
        ),
        monthly_installments_enabled:
          selectedMsi.id != 1 && selectedPaymentType.id != 2,
        ...(selectedMsi.id != 1 &&
          selectedPaymentType.id != 2 && {
            monthly_installments_options: selectedMsi.values,
          }),
        order_template: {
          line_items: selectedServices.map((service) => {
            return {
              object: "service_item",
              id: service.id,
              sku: service.sku,
              name: service.name,
              quantity: parseInt(service.qty),
              price: {
                unit_price: parseFloat(service.price * 100),
                total_amount: parseDecimalToInt(
                  service?.priceWithDiscount * 100
                ),
                discount: enabledDisccount
                  ? parseDecimalToInt(
                      service.discountType === "direct"
                        ? service?.discount * 100
                        : service?.discount
                    )
                  : 0,
                discount_type: enabledDisccount
                  ? service?.discountType
                  : "direct",
                // discount_amount: 0,
              },
            };
          }),
          currency: "MXN",
        },
        recurrent: selectedPaymentType.id == 2,
        ...(selectedPaymentType.id == 2 && {
          subscription_info: {
            name: concept,
            start_date: formData.subscriptionDate,
            interval: selectedPaymentInterval?.interval,
            frequency: selectedPaymentInterval?.frequency,
            expiry_count: parseInt(numberOfPayments),
            apply_charge: applyCharge,
            amount: enabledDisccount
              ? totalAmountWithDiscount * 100
              : totalAmount * 100,
            status_after_retry: "unpaid",
            currency: "mxn",
          },
        }),
        type: selectedPaymentType.id === 1 ? "unique" : "recurrent",
        description: description,
        html_description: formatToSingleLine(htmlDescription),
        ...(successUrl && {
          success_url: successUrl,
        }),
      };
      const response = await createUniversalPaymentLink(request);
      setPaymentLink(response);
    } catch (error) {
      setAlert({ active: true, type: "error", message: error.message });
    } finally {
      setLoading(false);
    }
  };

  const {
    selectService,
    removeService,
    changeServiceDiscount,
    changeServiceQty,
    changeServicePrice,
    onChangePaymentType,
    selectPaymentMethod,
    setExpirationDate,
    setShowTooltip,
    setSubscriptionDate,
  } = helperFunctions;

  useEffect(() => {
    if (enabledDisccount) {
      setTotalAmountWithDiscount(
        selectedServices.reduce(
          (accumulator, currentService) =>
            accumulator + currentService?.priceWithDiscount,
          0
        )
      );
    }
  }, [enabledDisccount, selectedServices]);

  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    setErrors({
      email: !emailRegex.test(email),
    });
  };

  useEffect(() => {
    setIsSubmitDisable(
      Object.values(errors).some((value) => value) ||
        !formData.email ||
        !formData.concept ||
        selectedServices.length == 0 ||
        formData.selectedPaymentMethods.length == 0 ||
        selectedServices.find(
          (service) =>
            service.price === null ||
            service.price === "" ||
            service.price < 1 ||
            service.qty === null ||
            service.qty === "" ||
            service.qty < 1
        ) ||
        (formData.selectedPaymentType.id === 2 &&
          (formData.numberOfPayments === null ||
            formData.numberOfPayments === "")) ||
        isNaN(totalAmount) ||
        (enabledDisccount && isNaN(totalAmountWithDiscount)) ||
        !formData.description ||
        (hasSuccessUrl && !formData.successUrl)
    );
  }, [formData]);

  return (
    <>
      {loading && <SecondaryLoader />}
      <div className="w-full">
        <Nav user={user}>
          <div className="w-full">
            <Link to="/payment-links">
              <span className="text-2xl text-white font-bold flex items-center cursor-pointer">
                <span className="text-white opacity-50 text-4xl mr-2">
                  <FontAwesomeIcon icon={faArrowLeft} />
                </span>
                <span className="text-lg pr-1">Crear link universal</span>
              </span>
            </Link>
          </div>
        </Nav>
      </div>

      <div className="w-full px-4">
        <div className="w-full mt-20 md:mt-4">
          <BreadCrumb roadMap={roadMap} />
        </div>
        <div className="w-full hidden md:flex text-3xl text-v2-input-text font-bold mt-5 justify-between">
          <a>
            <FontAwesomeIcon
              className="cursor-pointer"
              icon={faArrowLeft}
              onClick={() => history.goBack()}
            />
          </a>
          <span className="pl-4 w-full">Crear link universal</span>
        </div>

        {paymentLink ? (
          <UniversalLinkSuccess
            formData={formData}
            paymentLink={paymentLink}
            totalAmount={
              enabledDisccount ? totalAmountWithDiscount : totalAmount
            }
            expirationDate={expirationDate}
          />
        ) : (
          <div className="w-full mt-6 flex xs:flex-col lg:flex-row gap-4">
            <div className="xs:w-full lg:w-3/5 transition-all ease-out duration-400">
              <div className="w-full border border-v2-gray-border-tables bg-white rounded-10 pb-4 lg:mb-4">
                <HeadersDataTables
                  icon={faFileInvoiceDollar}
                  text="Datos del cobro"
                  background={true}
                  padding={0}
                  bgColor="bg-v2-blue-text-login"
                  textColor="text-white"
                />
                <div className="w-full px-4 pt-4 items-center text-xs font-bold text-v2-gray-title-client">
                  Correo electrónico:
                  <input
                    type="email"
                    className={`font-normal border-v2-gray-border-tables text-v2-text-bar-steps focus:ring-v2-blue-text-login focus:border-v2-blue-text-login transition-all block w-full px-4 my-1 rounded-md ${
                      errors?.email
                        ? "border-v2-red-status focus:border-v2-red-status focus:ring-v2-red-status"
                        : ""
                    }`}
                    placeholder="Ingresa un correo electrónico"
                    value={email}
                    onChange={(e) => {
                      setFormData({ ...formData, email: e.target.value });
                      validateEmail(e.target.value);
                    }}
                    name="email"
                    id="email"
                    minLength={11}
                  />
                </div>
                <div className="w-full px-4 pt-4 items-center text-xs font-bold text-v2-gray-title-client">
                  Concepto:
                  <input
                    type="text"
                    className="font-normal border-v2-gray-border-tables text-v2-text-bar-steps focus:ring-v2-blue-text-login focus:border-v2-blue-text-login transition-all block w-full px-4 my-1 rounded-md"
                    placeholder="Ingresa un concepto"
                    onChange={(e) =>
                      setFormData({ ...formData, concept: e.target.value })
                    }
                    defaultValue={concept}
                  />
                </div>

                <div className="w-full px-4 pt-4 items-center text-xs font-bold text-v2-gray-title-client">
                  Servicios:
                  <Select onChange={selectService}>
                    <option value={0} defaultValue>
                      Selecciona un servicio
                    </option>
                    {services
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map((service, i) => (
                        <option key={i} value={service.sku}>
                          {service.name} - {formatterCurrency(service.price)}
                        </option>
                      ))}
                  </Select>
                  {selectedServices.length > 0 && (
                    <>
                      <div className="bg-gray-100 text-base mt-4 p-4 rounded-lg text-gray-500 font-semibold flex justify-between">
                        ¿Otorgar descuento?
                        <div className="flex items-center gap-2">
                          <span className="text-gray-900 font-normal text-sm">
                            No
                          </span>
                          <Toogle
                            blue={true}
                            enabled={enabledDisccount}
                            setEnabled={setEnabledDisccount}
                          />
                          <span className="text-gray-900 font-normal text-sm">
                            Sí
                          </span>
                          <TagIcon className="text-green-500 w-5 h-5 inline-block" />
                        </div>
                      </div>
                      <TableServicesUniversalLink
                        services={selectedServices}
                        removeService={removeService}
                        changeServiceDiscount={changeServiceDiscount}
                        changeServiceQty={changeServiceQty}
                        changeServicePrice={changeServicePrice}
                        enabledDisccount={enabledDisccount}
                      />
                    </>
                  )}
                </div>

                <div className="w-full px-4 pt-4 items-center text-xs font-bold text-v2-gray-title-client">
                  Tipo de cobro:
                  <div className="py-1 flex justify-between gap-4 mt-1 text-base">
                    {paymentTypes.map((type, i) => (
                      <div
                        key={i}
                        className={`${
                          type.id == selectedPaymentType.id
                            ? "shadow-lg border-primary bg-primary/10"
                            : "border-v2-border-time"
                        } select-none transition-all ease-in-out duration-200 w-full relative border bg-white rounded-5 px-2 py-3 cursor-pointer font-normal text-center text-v2-input-text flex flex-col items-center justify-center`}
                        onClick={() => onChangePaymentType(type)}
                      >
                        <div
                          className={`${
                            type.id == selectedPaymentType.id
                              ? "visible"
                              : "invisible"
                          }  w-6 h-6 rounded-full bg-white text-2xl text-primary absolute -right-2 -top-3`}
                        >
                          <FontAwesomeIcon icon={faCircleCheck} />
                        </div>
                        <span>{type.name}</span>
                      </div>
                    ))}
                  </div>
                </div>

                {selectedPaymentType.id == 2 && (
                  <>
                    <div className="w-full px-4 items-center text-xs font-bold text-v2-gray-title-client block xl:flex gap-4">
                      <div className="flex w-full gap-4 pt-4">
                        <div className="w-full">
                          Inicio de cobro:
                          <div className="w-full relative my-1">
                            <DatePicker
                              value={subscriptionDate}
                              onChange={setSubscriptionDate}
                              locale="en"
                              wrapperClassName="block z-10 responsive-calendar"
                              inputClassName="w-full z-10 appearance-none cursor-pointer text-left py-2 px-1.5 font-normal border border-v2-gray-border-tables rounded-5 text-base text-v2-input-text outline-none responsive-calendar"
                              calendarClassName="block font-normal text-v2-input-text"
                              calendarPopperPosition="top"
                              colorPrimary="#2169AC"
                              minimumDate={minimumDate}
                            />
                            <FontAwesomeIcon
                              icon={faCalendar}
                              className="absolute w-5 z-10 h-5 right-2 top-[50%] -translate-y-1/2 text-primary"
                            />
                          </div>
                        </div>
                        <div className="w-full">
                          Número de cobros:
                          <div className="relative my-1 w-full text-base text-v2-text-bar-steps outline-none flex items-center justify-center">
                            <input
                              type="text"
                              inputMode="number"
                              className={`${
                                numberOfPayments == null ||
                                numberOfPayments === ""
                                  ? "border-v2-red-error-services-selected focus:ring-v2-red-error-services-selected focus:border-v2-red-error-services-selected"
                                  : "border-v2-gray-border-tables focus:ring-v2-blue-text-login focus:border-v2-blue-text-login"
                              } font-normal  text-v2-text-bar-steps transition-all block w-full pl-4 pr-6 rounded-md`}
                              value={numberOfPayments}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  numberOfPayments: e.target.value.replace(
                                    /\D/g,
                                    ""
                                  ),
                                })
                              }
                              maxLength={2}
                            />
                            <div className="absolute flex items-center top-0 right-0 bottom-0 text-sm mx-2">
                              <FontAwesomeIcon
                                onMouseEnter={() => setShowTooltip(true)}
                                onMouseLeave={() => setShowTooltip(false)}
                                onClick={() => setShowTooltip(true)}
                                icon={faCircleQuestion}
                                className="absolute w-5 z-10 h-5 right-2 top-[50%] -translate-y-1/2 text-v2-input-text cursor-pointer"
                              />
                              <div className="absolute w-28 bottom-12 xs:-right-2 lg:-right-7">
                                <Tooltip
                                  show={showTooltip}
                                  onClose={() => setShowTooltip(false)}
                                  arrowPosition={{ xs: "right", lg: "middle" }}
                                  message="Número total de cobros que se realizarán. 0 es indefinido"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="w-full pt-4">
                        Intervalo de cobro:
                        <div className="md:flex gap-2">
                          <Select
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                selectedPaymentInterval: paymentInterval.find(
                                  (interval) => interval.name == e.target.value
                                ),
                              })
                            }
                            value={selectedPaymentInterval.name}
                          >
                            {paymentInterval.map((interval, i) => (
                              <option key={i} value={interval.name}>
                                {interval.name}
                              </option>
                            ))}
                          </Select>
                        </div>
                      </div>
                    </div>
                    <div className="w-full px-4 items-center text-sm font-normal text-v2-input-text">
                      <div className="flex items-center min-w-[7.5rem]">
                        <label
                          className={`${
                            applyChargeDisabled
                              ? "opacity-60"
                              : "opacity-100 cursor-pointer"
                          } flex items-start select-none mt-1`}
                        >
                          <input
                            disabled={applyChargeDisabled}
                            type="checkbox"
                            checked={applyCharge}
                            onChange={() =>
                              setFormData({
                                ...formData,
                                applyCharge: !applyCharge,
                              })
                            }
                            className="mt-1 mr-1 accent-primary"
                          />
                          Realizar cobro al suscribir
                        </label>
                      </div>
                    </div>
                  </>
                )}

                <div className="w-full px-4 pt-4 items-center text-xs font-bold text-v2-gray-title-client">
                  Métodos de pago permitidos:
                  <div className="py-1 flex flex-col md:flex-row justify-between gap-4 mt-1">
                    {paymentMethods.map((paymentMethod, i) => {
                      const enabled =
                        selectedPaymentType.allowedPaymentMethods.includes(
                          paymentMethod.value
                        );
                      const selected =
                        enabled &&
                        selectedPaymentMethods.find(
                          (method) => method.id == paymentMethod.id
                        ) != null;
                      const noneSelected =
                        enabled && selectedPaymentMethods.length == 0;
                      let borderStyle = selected
                        ? "shadow-lg border-primary bg-primary/10"
                        : "border-v2-border-time";
                      if (noneSelected)
                        borderStyle = "border-v2-red-error-services-selected";
                      return (
                        <div
                          key={i}
                          onClick={() => {
                            if (enabled) selectPaymentMethod(paymentMethod);
                          }}
                          className={`${
                            enabled
                              ? "opacity-100 cursor-pointer"
                              : "opacity-50"
                          } ${borderStyle} select-none transition-all ease-in-out duration-200 w-full relative border bg-white rounded-5 px-2 py-3 text-sm font-normal text-center text-v2-input-text flex flex-col items-center justify-center`}
                        >
                          <div
                            className={`${
                              selected ? "visible" : "invisible"
                            } w-6 h-6 rounded-full text-2xl text-primary absolute -right-2 -top-3 bg-white`}
                          >
                            <div></div>
                            <FontAwesomeIcon icon={faCircleCheck} />
                          </div>
                          {typeof paymentMethod.icon === "string" ? (
                            <img
                              src={paymentMethod.icon}
                              className="h-8 object-contain"
                            />
                          ) : (
                            <FontAwesomeIcon
                              className="text-4xl"
                              icon={paymentMethod.icon}
                            />
                          )}
                          <span className="mt-1">
                            {paymentMethod.shortName}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                  {selectedPaymentMethods.length == 0 && (
                    <div className="w-full text-sm text-v2-red-error-services-selected flex flex-row items-center mt-1 font-normal">
                      <FontAwesomeIcon
                        className=""
                        icon={faTriangleExclamation}
                      />
                      <span className="ml-2">
                        Selecciona al menos un método de pago
                      </span>
                    </div>
                  )}
                </div>

                <div className="w-full px-4 items-center text-xs font-bold text-v2-gray-title-client block md:flex gap-4">
                  <div className="w-full pt-4">
                    Meses sin intereses:
                    <Select
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          selectedMsi: msi.find(
                            (msi) => msi.id == e.target.value
                          ),
                        })
                      }
                      value={selectedMsi.id}
                      disabled={
                        selectedPaymentType.id == 2 || totalAmount < 1500
                      }
                    >
                      {msi.map((msi, i) => (
                        <option key={i} value={msi.id}>
                          {msi.title}
                        </option>
                      ))}
                    </Select>
                  </div>
                  <div className="w-full pt-4">
                    Fecha de caducidad:
                    <div className="w-full relative my-1">
                      <DatePicker
                        value={expirationDate}
                        onChange={setExpirationDate}
                        locale="en"
                        wrapperClassName="block z-10 responsive-calendar"
                        inputClassName="w-full z-10 appearance-none cursor-pointer text-left py-2 px-1.5 font-normal border border-v2-gray-border-tables rounded-5 text-base text-v2-input-text outline-none responsive-calendar"
                        calendarClassName="block font-normal text-v2-input-text"
                        calendarPopperPosition="top"
                        colorPrimary="#2169AC"
                        minimumDate={minimumDate}
                      />
                      <FontAwesomeIcon
                        icon={faCalendar}
                        className="absolute w-5 z-10 h-5 right-2 top-[50%] -translate-y-1/2 text-primary"
                      />
                    </div>
                  </div>
                </div>

                <div className="bg-gray-100 mx-4 mt-4 p-4 rounded-lg text-gray-500 font-semibold flex justify-between">
                  ¿Agregar URL de éxito?
                  <div className="flex items-center gap-2">
                    <span className="text-gray-900 font-normal text-sm">
                      No
                    </span>
                    <Toogle
                      blue={true}
                      enabled={hasSuccessUrl}
                      setEnabled={setHasSuccessUrl}
                    />
                    <span className="text-gray-900 font-normal text-sm">
                      Sí
                    </span>
                  </div>
                </div>
                {hasSuccessUrl && (
                  <div className="w-full px-4 pt-4 items-center text-xs font-bold text-v2-gray-title-client">
                    URL:
                    <input
                      type="text"
                      className="font-normal border-v2-gray-border-tables text-v2-text-bar-steps focus:ring-v2-blue-text-login focus:border-v2-blue-text-login transition-all block w-full px-4 my-1 rounded-md"
                      placeholder="Ingresa una URL"
                      value={successUrl}
                      onChange={(e) =>
                        setFormData({ ...formData, successUrl: e.target.value })
                      }
                    />
                  </div>
                )}

                <div className="w-full px-4 pt-4 items-center text-xs font-bold text-v2-gray-title-client">
                  Descripción:
                  <input
                    type="text"
                    className="font-normal border-v2-gray-border-tables text-v2-text-bar-steps focus:ring-v2-blue-text-login focus:border-v2-blue-text-login transition-all block w-full px-4 my-1 rounded-md"
                    placeholder="Ingresa una descripción"
                    onChange={(e) =>
                      setFormData({ ...formData, description: e.target.value })
                    }
                    value={description}
                  />
                </div>

                <div className="w-full px-4 pt-4 items-center text-xs font-bold text-v2-gray-title-client">
                  <p className="mb-2">Descripción HTML:</p>
                  <CodeEditor
                    code={htmlDescription}
                    setCode={setHtmlDescription}
                  />
                </div>
              </div>
            </div>
            <UniversalLinkOverview
              formData={formData}
              dates={{ expirationDate, subscriptionDate }}
              selectedServices={selectedServices}
              totalAmount={
                enabledDisccount ? totalAmountWithDiscount : totalAmount
              }
              htmlDescription={formatToSingleLine(htmlDescription)}
              createUniversalLink={createUniversalLink}
              enabledDisccount={enabledDisccount}
              isSubmitDisable={isSubmitDisable}
            />
          </div>
        )}
      </div>
    </>
  );
}

export default CreateUniversalPaymentLink;
