import { faPaypal } from "@fortawesome/free-brands-svg-icons";
import {
  faCreditCard,
  faMoneyBill1Wave,
  faMoneyBillTransfer,
} from "@fortawesome/free-solid-svg-icons";

export const paymentMethods = [
  {
    id: 1,
    value: "card",
    name: "Tarjeta de crédito o débito",
    shortName: "Tarjeta",
    icon: faCreditCard,
    imageUrl:
      "https://cdn.fixat.mx/assets/img/no-webp/aceptamos-pago-con-tarjeta.png",
  },
  {
    id: 2,
    value: "paypal",
    name: "Pago con Paypal",
    shortName: "Paypal",
    icon: faPaypal,
    imageUrl: "https://cdn.fixat.mx/assets/img/no-webp/paypal.png",
  },
  {
    id: 3,
    value: "cash",
    name: "Pago en fectivo",
    shortName: "Efectivo",
    icon: faMoneyBill1Wave,
    imageUrl: "https://cdn.fixat.mx/assets/img/no-webp/oxxo_short.svg",
  },
  {
    id: 4,
    value: "transfer",
    name: "Transferencia bancaria",
    shortName: "Transferencia",
    icon: faMoneyBillTransfer,
    imageUrl: "https://cdn.fixat.mx/assets/img/no-webp/spei.png",
  },
];

export const paymentTypes = [
  {
    id: 1,
    name: "Cobro único",
    allowedPaymentMethods: ["card", "paypal", "cash", "transfer"],
  },
  {
    id: 2,
    name: "Cobro recurrente",
    allowedPaymentMethods: ["card"],
  },
];

export const paymentInterval = [
  {
    id: 1,
    name: "Mensual",
    interval: "month",
    frequency: 1,
  },
  {
    id: 2,
    name: "Semanal",
    interval: "week",
    frequency: 1,
  },
  {
    id: 3,
    name: "Quincenal",
    interval: "half_month",
    frequency: 1,
  },
  {
    id: 4,
    name: "Bimestral",
    interval: "month",
    frequency: 2,
  },
  {
    id: 5,
    name: "Trimestral",
    interval: "month",
    frequency: 3,
  },
  {
    id: 6,
    name: "Semestral",
    interval: "month",
    frequency: 6,
  },
  {
    id: 7,
    name: "Anual",
    interval: "year",
    frequency: 1,
  },
];

export const periodicity = {
  unique: " pago único",
  yearly: " anual",
  monthly: " mensual",
  bimonthly: " bimestral",
};

export const defaultCode = `
<div class='flex flex-col pb-6 w-full'>
  <p class='text-[#555555] text-sm font-bold mb-2'>
    Promoción exclusiva para asistentes al webinar
  </p>
  <div class='flex'>
    <div
      style='
        background-color: rgb(8 145 178);
        border-radius: 0.375rem;
        height: fit-content;
        display: inline-block;
        margin-right: 0.375rem;
        padding: 0.25rem;
        color: white;
      '
    >
      <svg
        width='18'
        height='18'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M11 5.88218V19.2402C11 20.2121 10.2121 21 9.24018 21C8.49646 21 7.83302 20.5325 7.58288 19.8321L5.43647 13.6829M18 13C19.6569 13 21 11.6569 21 10C21 8.34315 19.6569 7 18 7M5.43647 13.6829C4.0043 13.0741 3 11.6543 3 10C3 7.79086 4.79086 6 6.99999 6H8.83208C12.9327 6 16.4569 4.7659 18 3L18 17C16.4569 15.2341 12.9327 14 8.83208 14L6.99998 14C6.44518 14 5.91677 13.887 5.43647 13.6829Z'
          stroke='#ffffff'
          stroke-width='2'
          stroke-linecap='round'
          stroke-linejoin='round'
        ></path>
      </svg>
    </div>
    <p class='text-[#828282] text-xs font-normal text-pretty'>
      Obtén un análisis de situación fiscal a precio especial y una llamada
      especializada con un contador certificado y experto.
    </p>
  </div>
  <div class='flex justify-between items-start gap-x-4 mt-2'>
    <ul class='flex flex-col gap-2'>
      <li
        class='list-disc text-[#555555] leading-[22px] text-sm font-normal ml-4'
      >
        Declaracion Anual 2024 GRATIS
      </li>
      <li
        class='list-disc text-[#555555] leading-[22px] text-sm font-normal ml-4'
      >
        Acceso al Portal de Facturación GRATIS
      </li>
      <li
        class='list-disc text-[#555555] leading-[22px] text-sm font-normal ml-4'
      >
        VIDEOLLAMADA CON CONTADOR EXPERTO
      </li>
    </ul>
  </div>
</div>
`;
